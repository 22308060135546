.item-products {
  display: flex;
  flex-direction: row;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .item-products {
    flex-direction: column;
    max-width: 75%;
  }
}

@media (max-width: 580px) {
  .item-products {
    flex-direction: column;
    max-width: 50%;
  }
}

.item-product-img {
  width: 300px;
  /* Set the width to 300 pixels */
  height: 200px;
  /* Set the height to 200 pixels */
  object-fit: cover;
  /* Ensures the image covers the entire area without distortion */
  /* border: 2px solid #ccc; */
  /* Optional: Add a border */
  border-radius: 10px;
  /* Optional: Add rounded corners */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  /* Optional: Add a shadow */
}

.list-product-categories-text {
  color: black;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.list-product-categories-text:hover {
  color: green;
  font-family: 'Open Sans', sans-serif;
  /* background-color: #ccc; */
  font-size: 18px;
  /* text-decoration: solid; */
  font-style: bold;
}

.box-term {
  /* display: flex; */
  flex-direction: row;
  /* justify-content: space-between; */
  /* align-items: center; */
  padding: 10px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: black;
  /* text-decoration-color: yellow; */
}

@media (max-width: 765px) {
  .box-term {
    color: white;
    background-color: yellow;

  }
}


.full-width {
  width: 100%;
}